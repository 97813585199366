// vuex store as a wrapper for the GenerIO API


//// IMPORTS
import { accessServerFile, accessServerImage, accessClientImage, lookup } from '@/utils.js'


//// REST CONSTANTS
const REST_POST = 'POST'
const REST_GET = 'GET'
const REST_PUT = 'PUT'
const REST_DELETE = 'DELETE'


//// ROUTE CONSTANTS
const ROUTE_BASE = '/api'

// users routes
const ROUTE_USERS_REGISTER = '/users/register'
const ROUTE_USERS_ACTIVATE = '/users/activate'
const ROUTE_USERS_DEACTIVATE = '/users/deactivate'
const ROUTE_USERS_TOKEN = '/users/token'
const ROUTE_USERS_PASSWORD_FORGOT = '/users/password/forgot'
const ROUTE_USERS_PASSWORD_CHANGE = '/users/password/change'
const ROUTE_USERS_INFO = '/users/info'
const ROUTE_USERS_DESIGN = '/users/design'
const ROUTE_USERS_NEWSLETTER = '/users/newsletter'

// assets routes
const ROUTE_ASSETS = '/assets' 
const ROUTE_ASSETS_ = '/assets/'
const ROUTE_ASSETS_APPEND_STATUS = '/status'
const ROUTE_ASSETS_APPEND_THUMBNAIL = '/thumbnail'
const ROUTE_ASSETS_APPEND_SHARE = '/share' //TODO fix in API
const ROUTE_ASSETS_APPEND_FILES = '/files'
const ROUTE_ASSETS_APPEND_FILE_ = '/file/'
const ROUTE_ASSETS_APPEND_ADDITIONAL = '/additional'

// texts routes
const ROUTE_TEXTS_COLOR = '/texts/color'
const ROUTE_TEXTS_TRANSLATE = '/texts/translate' 

// images routes
const ROUTE_IMAGES_FROMTEXT_GENERATE = '/images/from-text/generate'
const ROUTE_IMAGES_FROMIMAGE_GENERATE = '/images/from-image/generate' 
const ROUTE_IMAGES_FROMDEPTH_GENERATE = '/images/from-depth/generate'
const ROUTE_IMAGES_SEGMENTATION_GENERATE = '/images/segmentation/generate'

// layers routes
const ROUTE_LAYERS_FROMTEXT_GENERATE = '/layers/from-text/generate'
const ROUTE_LAYERS_FROMIMAGE_GENERATE = '/layers/from-image/generate' 

// textures routes
const ROUTE_TEXTURES_ALBEDO_GENERATE = '/textures/albedo/generate'
const ROUTE_TEXTURES_DIFFUSE_GENERATE = '/textures/diffuse/generate'
const ROUTE_TEXTURES_BUMP_GENERATE = '/textures/bump/generate'
const ROUTE_TEXTURES_DISPLACEMENT_GENERATE = '/textures/displacement/generate'
const ROUTE_TEXTURES_NORMAL_GENERATE = '/textures/normal/generate'

// models routes
const ROUTE_MODELS_FROMIMAGE_GENERATE = '/models/from-image/generate' 
const ROUTE_MODELS_FROMLAYER_GENERATE = '/models/from-layer/generate' 
const ROUTE_MODELS_FROMSCRIPT_GENERATE = '/models/from-script/generate'
const ROUTE_MODELS_ANYTHINGTOOBJ_CONVERT = '/models/anything-to-obj/convert'
const ROUTE_MODELS_GLBTOANYTHING_CONVERT = '/models/glb-to-anything/convert'

// logging routes
const ROUTE_LOGGING_EVENT = '/logging/event'
const ROUTE_LOGGING_BUG = '/logging/bug'
const ROUTE_LOGGING_FEEDBACK = '/logging/feedback'
const ROUTE_LOGGING_VOTE = '/logging/vote'

// monitoring routes
const ROUTE_MONITORING_STATUS = '/monitoring/status'

// administration routes
const ROUTE_ADMINISTRATION_USERS_ACTIVITY = '/administration/users/activity'
const ROUTE_ADMINISTRATION_ASSETS_ACTIVITY = '/administration/assets/activity'
const ROUTE_ADMINISTRATION_NEWSLETTERS = '/administration/newsletters'
const ROUTE_ADMINISTRATION_NEWSLETTERS_SEND = '/administration/newsletters/send'


//// MONITORING CONSTANTS

// status
const MONITORING_STATUS_UP = 'up'
const MONITORING_STATUS_DOWN = 'down'
const MONITORING_STATUS_UNDEFINED = 'unknown'


//// ASSET CONSTANTS

// types
// (future: graphic / video)
const ASSET_TYPE_TEXT = 'text'
const ASSET_TYPE_IMAGE = 'image'
const ASSET_TYPE_LAYER = 'layer'
const ASSET_TYPE_TEXTURE = 'texture'
const ASSET_TYPE_MODEL = 'model'

// subtypes
const ASSET_SUBTYPE_TEXT_COLOR = 'color'
const ASSET_SUBTYPE_TEXT_TRANSLATION = 'translation'
const ASSET_SUBTYPE_IMAGE_PHOTO = 'photo'
//const ASSET_SUBTYPE_IMAGE_SKETCH = 'sketch'
const ASSET_SUBTYPE_LAYER_PHOTO = 'photo'
const ASSET_SUBTYPE_TEXTURE_PHYSICAL = 'physical'
const ASSET_SUBTYPE_TEXTURE_NONPHYSICAL = 'nonphysical'
const ASSET_SUBTYPE_MODEL_NONE = 'none'

// source
//TODO rethink if it is not just a boolean (generated / or not)
const ASSET_SOURCE_UPLOAD = 'upload'
const ASSET_SOURCE_CREATION = 'creation'
const ASSET_SOURCE_GENERATION = 'generation'

// status
const ASSET_STATUS_UNREQUESTED = 'unrequested'
const ASSET_STATUS_REQUESTED = 'requested'
const ASSET_STATUS_RETRIEVED = 'retrieved'
const ASSET_STATUS_UNRETRIEVABLE = 'unretrievable'


//// VUEX STORE
export const api = {
    namespaced: true,
    state: {
        active: true,
        status: MONITORING_STATUS_UNDEFINED,
        assets: [],




        materialComponents: [],
        lastComponent: 0,
        votes: {}
    },
    mutations: {
        // mutation to set api active or disabled
        SET_ACTIVE(state, active) {
            state.active = active
        },

        // mutation to set api status
        SET_STATUS(state, status) {
            state.status = status
        },

        // mutation to reset assets list
        RESET_ASSETS(state) {
            state.assets = []
        },
        // mutation to add asset to assets list
        ADD_ASSET(state, asset) {
            // ensure correct format
            asset.created = new Date(asset.created)
            // push asset to assets list
            state.assets.push(asset)
        },
        // mutation to remove asset from assets list 
        REMOVE_ASSET(state, assetId) {
            const index = state.assets.findIndex(asset => asset.id === assetId)
            if (index !== -1) state.assets.splice(index, 1)
        },






        // mutation to add material component
        ADD_COMPONENT(state, component) {
            state.materialComponents.push(component)
        },
        // mutation to remove material component
        REMOVE_COMPONENT(state, componentId) {
            const index = state.materialComponents.findIndex(component => component.id === componentId)
            if (index !== -1) state.materialComponents.splice(index, 1)
        },
        // mutation to add vote
        SET_VOTE(state, { key, value }) {
            state.votes[key] = value
        }
    },
    getters: {
        keyStatusUp: () => MONITORING_STATUS_UP,
        keyStatusDown: () => MONITORING_STATUS_DOWN,
        keyStatusUndefned: () => MONITORING_STATUS_UNDEFINED,

        keyTypeText: () => ASSET_TYPE_TEXT,
        keyTypeImage: () => ASSET_TYPE_IMAGE,
        keyTypeLayer: () => ASSET_TYPE_LAYER,
        keyTypeTexture: () => ASSET_TYPE_TEXTURE,
        keyTypeModel: () => ASSET_TYPE_MODEL,

        keySubtypeTextColor: () => ASSET_SUBTYPE_TEXT_COLOR,
        keySubtypeTextTranslation: () => ASSET_SUBTYPE_TEXT_TRANSLATION,
        keySubtypeImagePhoto: () => ASSET_SUBTYPE_IMAGE_PHOTO,
        keySubtypeImageSketch: () => ASSET_SUBTYPE_IMAGE_SKETCH,
        keySubtypeLayerPhoto: () => ASSET_SUBTYPE_LAYER_PHOTO,
        keySubtypeTexturePhysical: () => ASSET_SUBTYPE_TEXTURE_PHYSICAL,
        keySubtypeTextureNonphysical: () => ASSET_SUBTYPE_TEXTURE_NONPHYSICAL,
        keySubtypeModelNone: () => ASSET_SUBTYPE_MODEL_NONE,

        keySourceUpload: () => ASSET_SOURCE_UPLOAD,
        keySourceCreation: () => ASSET_SOURCE_CREATION,
        keySourceGeneration: () => ASSET_SOURCE_GENERATION,

        keyStatusUnrequested: () => ASSET_STATUS_UNREQUESTED,
        keyStatusRequested: () => ASSET_STATUS_REQUESTED,
        keyStatusRetrieved: () => ASSET_STATUS_RETRIEVED,
        keyStatusUnretrievable: () => ASSET_STATUS_UNRETRIEVABLE,

        /*
        newAsset: () => (type, subtype, source) => {
            return {
                id: null,
                app: null,
                type,
                subtype,
                source,
                parent: null,
                created: new Date(),
                thumbnail: {
                    data: null,
                    status: ASSET_STATUS_UNREQUESTED,
                    url: '/assets/0/thumbnail' 
                },
                files: {},
                additional: null
            }
        },
        */

        isActive: state => state.active,
        isStatusUp: state => state.status === MONITORING_STATUS_UP,
        isStatusDown: state => state.status === MONITORING_STATUS_DOWN,
        isStatusUndefined: state => state.status === MONITORING_STATUS_UNDEFINED,

        getStatus: state => state.status,

        /*
        TODO getAssets and getAsset are the same as the same named actions ...
        can be solved by different namings / better to first check state and only then cawl if not available
        */
        getAssets: state => state.assets,
        getAsset: state => (assetId) => {
            return state.assets.find(asset => asset.id === assetId)
        },

        getTextAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_TEXT)
        },
        getImageAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_IMAGE)
        },
        getLayerAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_LAYER)
        },
        getTextureAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_TEXTURE)
        },
        getModelAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_MODEL)
        },


        getFilteredAssets: state => (app, type = null, subtype = null, source = null) => {
            return state.assets.filter(asset => {
                return asset.app === app && 
                       (type === null || asset.type === type) &&
                       (subtype === null || asset.subtype === subtype) &&
                       (source === null || asset.source === source)
            })
        },

        getAssetReferences: state => (assetId) => {
            return state.assets.filter(asset => asset.parent === assetId)
        },



    

        
        newComponent: state => (type, tag, prompt) => {

            // create new material component
            const component = {
                id: state.lastComponent++,
                type: type,         // type of material component (color or texture)
                tag: tag,           // component "source" tag (import, generic, or wood)
                prompt: prompt,     // unique query for generated material component
                ready: false,       // true, when material component is generated
                reload: false,      // true, when textures need reload
                data: null          // component data
            }

            // configure material component (type dependent)
            switch (type) 
            {
                // color
                case 'color':
                    component.data = {
                        ambientColor: { r: 255, g: 255, b: 255 },
                        diffuseColor: { r: 255, g: 255, b: 255 },
                        specularColor: { r: 255, g: 255, b: 255 },
                        specularIntensity: 30,
                        emissiveColor: { r: 0, g: 0, b: 0 },
                        emissiveIntensity: 0,
                        roughness: 1.0,
                        metalness: 0.0
                    }
                    break

                // texture
                case 'texture':
                    component.data = {
                        albedoMap: null,
                        bumpMap: null,
                        normalMap: null
                    }
                    break
            }

            // return material component
            return component
        },
        
        getActive: state => state.active, //TODO remove
        getVersion: state => state.version,
        getComponents: state => (type, tag) => {
            return state.materialComponents.filter(
                component => component.type === type && component.tag === tag
            )  
        },
        getComponent: state => (id) => {
            return state.materialComponents.find(
                component => component.id === id
            )
        },
        getComponentByPrompt: state => (type, tag, prompt) => {
            return state.materialComponents.find(component => 
                component.type === type &&
                component.tag === tag && 
                JSON.stringify(component.prompt) === JSON.stringify(prompt)
            )
        },
        getComponentCopy: (state, getters) => (id) => {
            const component = getters.getComponent(id)
            const { data, ...rest } = component
            const componentCopy = {
              ...rest,
              ...data
            }
            return componentCopy
        },
        getDefaultComponent: state => (type, tag) => {
            return state.materialComponents.find(
                component => component.type === type && component.tag === tag
            )
        },
        getDefaultComponentCopy: (state, getters) => (type, tag) => {
            const component = getters.getDefaultComponent(type, tag)
            const { data, ...rest } = component
            const componentCopy = {
              ...rest,
              ...data
            }
            return componentCopy
        },
        getVote: state => (key) => {
            return state.votes[key]
        },
        isVote: state => (key) => key in state.votes
    },
    actions: {

        //// INITIALIZATION

        // initialize the API
        async initializeAPI({ state, commit, dispatch, getters, rootGetters })
        {
            // retrieve status
            dispatch('fetchStatus')

            // empty list of assets
            commit('RESET_ASSETS')

            // get all assets if user is authenticated
            // IMPORTANT: requires initializeApp to be called before initializeAPI
            if (rootGetters['app/isAuthenticated']) dispatch('getAssets')
            

            



            ////
            // TODO refactor the following code
            ////

            // reset material components
            state.materialComponents = []

            // default texture templates
            const defaultTemplates = [
                {
                    type: 'color',
                    tag: 'generic'
                },
                { 
                    type: 'texture', 
                    tag: 'generic', 
                    path: './textures/generic/default-generic.png' 
                },
                { 
                    type: 'texture',
                    tag: 'wood', 
                    path: './textures/wood/default-wood.png' 
                },
                { 
                    type: 'texture',
                    tag: 'sand', 
                    path: './textures/sand/default-sand.png' 
                },
                { 
                    type: 'texture',
                    tag: 'brick', 
                    path: './textures/brick/default-brick.png' 
                }
            ]

            // iterate through all templates
            defaultTemplates.forEach(template => {

                // new material component
                const component = getters.newComponent(template.type, template.tag, null)

                // add material component to store
                commit('ADD_COMPONENT', component)

                // color material component
                if (template.type === 'color') 
                {
                    // flag component as ready
                    component.ready = true
                }

                // texture material component
                else if (template.type === 'texture')
                {
                    // load texture
                    accessServerImage(template.path, function(base64) {

                        // persist the requested data
                        component.data.albedoMap = base64

                        // flag texture as reload necessary
                        component.reload = true

                        // flag component as ready
                        component.ready = true
                    })
                }
            })
        },













        //// USERS

        // register
        // 
        // success: false
        // 'The provided email is not valid.'
        // 'Account with email exists already.'
        async registerUser({ commit }, { email, mode, theme, callbackSuccess, callbackError }) 
        {
            // payload
            const payload = {
                email,
                mode,
                theme
            }

            // TODO add logging of event and bug by wrapping success
            // use then / catch javascript mechanic

            accessRoute(
                ROUTE_USERS_REGISTER, 
                payload, 
                REST_POST, 
                null,
                callbackSuccess, 
                callbackError) 
        },

        // activate
        //
        // success: false
        // 'The activation link is not valid.'
        async activateUser({ commit }, { token, callbackSuccess, callbackError })
        {
            const payload = {
                token
            }

            accessRoute(
                ROUTE_USERS_ACTIVATE, 
                payload, 
                REST_PUT, 
                null,
                callbackSuccess, 
                callbackError) 
        },

        // deactivate 
        //
        // 401: unauthorized
        async deactivateUser({ commit }, { token, callbackSuccess, callbackError })
        {
            accessRoute(
                ROUTE_USERS_DEACTIVATE, 
                null, 
                REST_PUT, 
                token, 
                callbackSuccess, 
                callbackError) 
        },

        // token
        // 
        // success: false
        // 'Username or password incorrect.'
        // 'Account not activated yet.'
        // 'Account has been deleted.'
        async tokenUser({ commit, rootGetters }, { username, password, callbackSuccess, callbackError })
        {
            const payload = {
                username, 
                password
            }

            accessRoute(
                ROUTE_USERS_TOKEN, 
                payload, 
                REST_POST, 
                null,
                callbackSuccess, 
                callbackError) 
        },

        // password forgot
        //
        // success: false
        // 'If the account exists, you will receive an email with a password reset link.'
        async passwordForgot({ commit }, { username, callbackSuccess, callbackError })
        {
            const payload = {
                accountname: username 
            }

            accessRoute(
                ROUTE_USERS_PASSWORD_FORGOT, 
                payload, 
                REST_POST, 
                null,
                callbackSuccess, 
                callbackError) 
        },

        // password change
        //
        // 401: unauthorized
        async passwordChange({ commit }, { token, password, callbackSuccess, callbackError })
        {
            const payload = {
                new_password: password
            }

            accessRoute(
                ROUTE_USERS_PASSWORD_CHANGE, 
                payload, 
                REST_PUT, 
                token,
                callbackSuccess, 
                callbackError) 
        },

        // get user info
        //
        // 401: unauthorized
        async getUserInfo({ commit }, { token, callbackSuccess, callbackError })
        {
            accessRoute(
                ROUTE_USERS_INFO, 
                null, 
                REST_GET, 
                token,
                callbackSuccess, 
                callbackError) 
        },

        // get user design
        //
        // 401: unauthorized
        async getUserDesign({ commit }, { token, callbackSuccess, callbackError })
        {
            accessRoute(
                ROUTE_USERS_DESIGN,
                null,
                REST_GET,
                token,
                callbackSuccess,
                callbackError
            )
        },

        // change user design
        //
        // 401: unauthorized
        async changeUserDesign({ commit }, { token, mode, theme, callbackSuccess, callbackError })
        {
            const payload = {
                mode, 
                theme
            }

            accessRoute(
                ROUTE_USERS_DESIGN,
                payload,
                REST_PUT,
                token,
                callbackSuccess,
                callbackError
            )
        },

        // get newsletter subscription
        // 
        // 401: unauthorized
        async getNewsletterSubscription({ commit }, { token, callbackSuccess, callbackError })
        {
            accessRoute(
                ROUTE_USERS_NEWSLETTER,
                null,
                REST_GET,
                token,
                callbackSuccess,
                callbackError
            )
        },

        // change newsletter subscription
        // 
        // 401: unauthorized
        async changeNewsletterSubscription({ commit }, { token, subscribed, callbackSuccess, callbackError })
        {
            const payload = {
                subscribed
            }

            accessRoute(
                ROUTE_USERS_NEWSLETTER,
                payload,
                REST_POST,
                token,
                callbackSuccess,
                callbackError
            )
        },


        //// ASSETS

        // get all assets for a user
        //
        // 401: unauthorized
        async getAssets({ commit, dispatch, rootGetters }, { 
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (result) => {

                // clear list of assets 
                commit('RESET_ASSETS')

                // iterate through all assets
                result.forEach(asset => {

                    // add current asset
                    commit('ADD_ASSET', asset)
                })

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(result)
            }

            // callback wrapper function for error case
            const callbackErrorWrapper = (error) => {

                // log error
                dispatch('logBug', {
                    name: 'vuex-api.js | actions | getAssets failed',
                    description: 'status [' + error.status + "] and text [" + error.statusText + "]"
                })

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // access route
            accessRoute(
                ROUTE_ASSETS,
                null, 
                REST_GET,
                token,
                callbackSuccessWrapper,
                callbackErrorWrapper
            )
        },

        //
        async fetchAsset({ commit, rootGetters }, {
            assetId,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // access route
            accessRoute(
                ROUTE_ASSETS_ + assetId,
                null, 
                REST_GET,
                token,
                callbackSuccess,
                callbackError
            )
        },

        // 
        async createAsset({ commit, rootGetters }, {
            type, 
            subtype, 
            source, 
            files, 
            thumbnail, 
            additional, 
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // prepare server request
            const payload = {
                app, 
                type, 
                subtype, 
                source, 
                files, 
                thumbnail, 
                additional
            }

            // callback wrapper function for success case
            const callbackSuccessWrapper = (asset) => {

                // add local copy of asset
                commit('ADD_ASSET', asset)

                // thumbnail
                asset.thumbnail.data = thumbnail
                asset.thumbnail.status = ASSET_STATUS_RETRIEVED

                // files
                // TODO adjust for multiple files
                asset.files['default'].data = files
                asset.files['default'].status = ASSET_STATUS_RETRIEVED

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(asset)
            }

            // callback wrapper function for error case
            const callbackErrorWrapper = (error) => {

                // TODO do we even need the wrapper or is normal error handling enough?
                console.log("error")
                console.log(error)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // request
            accessRoute(
                ROUTE_ASSETS,
                payload, 
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackErrorWrapper
            )
        },

        // 
        async deleteAsset({ commit, rootGetters }, {
            asset,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (result) => {

                // remove local copy of asset
                commit('REMOVE_ASSET', asset.id)

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(result)
            }
            
            // callback wrapper function for error case
            const callbackErrorWrapper = (error) => {
            
                // TODO do we even need the wrapper or is normal error handling enough?
                console.log("error")
                console.log(error)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // route to delete asset
            accessRoute(
                ROUTE_ASSETS_ + asset.id,
                null, 
                REST_DELETE,
                token,
                callbackSuccessWrapper,
                callbackErrorWrapper
            )
        },


        /*
        async getAssetAdditional({  }, {

        } = {})
        {

        }
        */

        async updateAssetAdditional({ rootGetters }, {
            asset,
            additional,
            callbackSuccess = null,
            callbackError = null
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // payload
            const payload = {
                additional
            }

            // 
            accessRoute(
                ROUTE_ASSETS_ + asset.id + ROUTE_ASSETS_APPEND_ADDITIONAL,
                payload, 
                REST_PUT,
                token,
                callbackSuccess,
                callbackError
            )
        },

        // 
        async getAssetStatus({ rootGetters }, {
            asset,
            untilReady = false,
            callbackSuccess = null, 
            callbackError = null 
        }={})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // time increments
            const timeIncrement = 500

            //
            const request = (timeCurrent) => {

                // increment time for next request
                timeCurrent += timeIncrement

                const callbackSuccessWrapper = (result) => {

                    if (untilReady && !result.ready)
                    {
                        // recursive callback
                        setTimeout(() => request(timeCurrent), timeCurrent)
                    }
                    else
                    {
                        // trigger callback
                        if (callbackSuccess && typeof callbackSuccess === 'function')
                            callbackSuccess(result)
                    }
                }
    
                // 
                accessRoute(
                    ROUTE_ASSETS_ + asset.id + ROUTE_ASSETS_APPEND_STATUS,
                    null, 
                    REST_GET,
                    token,
                    callbackSuccessWrapper,
                    callbackError
                )
            }

            //
            request(0)
        },

        // get thumbnail for asset
        async getAssetThumbnail({ dispatch, rootGetters }, {
            asset,
            callbackSuccess = null, 
            callbackError = null 
        } = {}) 
        {
            // set status to indicate thumbnail is being requested
            asset.thumbnail.status = ASSET_STATUS_REQUESTED

            // get current user token
            const token = rootGetters['app/getToken']

            // function to bundle the request for the thumbnail
            const requestThumbnail = () => {

                // callback wrapper function for success case
                const callbackSuccessWrapper = (result) => {

                    // update thumbnail of asset
                    asset.thumbnail.data = result
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(result)
                }

                // callback wrapper function for error case
                const callbackErrorWrapper = (error) => {

                    // update thumbnail status
                    // [is retrieved because otherwise it enters endless loop]
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED

                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                }

                // url for thumbnail
                let url = asset.thumbnail.url

                // download animated thumbnails for models
                if (asset.type == rootGetters['api/keyTypeModel'])
                    url += '?animate=True'

                // call route for retrieving the thumbnail
                accessRoute(
                    url,
                    null, 
                    REST_GET,
                    token,
                    callbackSuccessWrapper,
                    callbackErrorWrapper
                )
            }

            // wait for asset to be ready
            dispatch('getAssetStatus', {
                asset,
                untilReady: true,
                callbackSuccess: (result) => {
                    requestThumbnail()
                },
                callbackError
            })
        },

        async changeAssetShare({ rootGetters }, {
            asset,
            shared,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // payload
            const payload = {
                asset_id: asset.id,
                shared: shared
            }

            // call route for changing asset sharing
            accessRoute(
                ROUTE_ASSETS_ + asset.id + ROUTE_ASSETS_APPEND_SHARE,
                payload, 
                REST_PUT,
                token,
                callbackSuccess,
                callbackError
            )
        },

        async getAssetShare({}, {
            assetId,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // call route for changing asset sharing
            accessRoute(
                ROUTE_ASSETS_ + assetId + ROUTE_ASSETS_APPEND_SHARE,
                null, 
                REST_GET,
                null,
                callbackSuccess,
                callbackError
            )
        },


        // 
        async getAssetFiles({}, {

        } = {})
        {
                    
        
        },

        // 
        async getAssetFile({ dispatch, getters, rootGetters }, {
            asset,
            fileKey = 'default', 
            callbackSuccess = null, 
            callbackError = null 
        } = {}) 
        {
            // 
            const file = asset.files[fileKey]

            // 
            if (file == null) return 

            //
            if (file.status === getters.keyStatusRetrieved)
            {
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(file.data)

                return
            }

            // set status to indicate file is being requested
            file.status = ASSET_STATUS_REQUESTED

            // get current user token
            const token = rootGetters['app/getToken']

            // function to bundle the request for the file
            const requestFile = () => {

                // callback wrapper function for success case
                const callbackSuccessWrapper = (result) => {

                    // update file of asset
                    file.data = result
                    file.status = ASSET_STATUS_RETRIEVED

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(result)
                }

                // callback wrapper function for error case
                const callbackErrorWrapper = (error) => {

                    // update file status
                    // [is retrieved because otherwise it enters endless loop]
                    file.status = ASSET_STATUS_RETRIEVED

                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                }

                // call route for retrieving the file
                accessRoute(
                    file.url,
                    null, 
                    REST_GET,
                    token,
                    callbackSuccessWrapper,
                    callbackErrorWrapper
                )
            }

            // wait for asset to be ready
            dispatch('getAssetStatus', {
                asset,
                untilReady: true,
                callbackSuccess: (result) => {
                    requestFile()
                },
                callbackError
            })

        },


 








        //// COMPONENTS

        // remove material component
        removeComponent({ commit }, componentId) {
            // remove material component to store
            commit('REMOVE_COMPONENT', componentId)
        },


        //// PARAMETER

        // generate color
        async generateColor({ rootState, commit, getters, dispatch }, 
            { 
                prompt, 
                callbackSuccess = null, 
                callbackError = null 
            })
        {
            // new material component for the generated color
            const component = getters.newComponent('color', 'generic', prompt)

            // add material component to store
            commit('ADD_COMPONENT', component)

            // function for error handling
             const callbackErrorWrapper = (error) => {

                // remove from list again
                dispatch('removeComponent', component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            } 

            // function for success handling
            const callbackSuccessWrapper = (result) => {

                // extract json
                const json = result.response

                // check if json is valid
                if (json)
                {
                    // get diffuse color
                    if (json.Diffuse && json.Diffuse.r && json.Diffuse.g && json.Diffuse.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Diffuse.r > 1 || 
                                            json.Diffuse.g > 1 || 
                                            json.Diffuse.b > 1

                        component.data.diffuseColor = {
                            r: largerRange ? json.Diffuse.r : Math.floor(json.Diffuse.r * 255),
                            g: largerRange ? json.Diffuse.g : Math.floor(json.Diffuse.g * 255),
                            b: largerRange ? json.Diffuse.b : Math.floor(json.Diffuse.b * 255),
                        }
                    }

                    // get emissive color
                    if (json.Emissive && json.Emissive.r && json.Emissive.g && json.Emissive.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Emissive.r > 1 || 
                                            json.Emissive.g > 1 || 
                                            json.Emissive.b > 1

                        component.data.emissiveColor = {
                            r: largerRange ? json.Emissive.r : Math.floor(json.Emissive.r * 255),
                            g: largerRange ? json.Emissive.g : Math.floor(json.Emissive.g * 255),
                            b: largerRange ? json.Emissive.b : Math.floor(json.Emissive.b * 255),
                        }
                    }

                    // get emissive exponent
                    if (json['Emissive Strength'])
                    {
                        const exp = Number(json['Emissive Strength'])
                        component.data.emissiveIntensity = Math.max(Math.min(exp, 1), 0)
                    }

                    // get advanced attributes
                    if (json.Roughness) 
                        component.data.roughness = json.Roughness
                    if (json.Metalness) 
                        component.data.metalness = json.Metalness

                    // set component as ready
                    component.ready = true

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(component)
                }
                else 
                    errorHandling("Color could not be parsed.")
            }

            // api is active
            if (rootState.api.active)
            {
                // prepare server request
                const payload = {
                    query: component.prompt
                }

                // send server request
                await accessRoute(
                    ROUTE_TEXTS_COLOR, 
                    payload, 
                    REST_POST,
                    null,
                    callbackSuccessWrapper,
                    callbackErrorWrapper
                )
            }

            // api is inactive
            else
            {
                // color lookup for offline processing
                const colorLookup = {
                    "brick": ["brick", "ziegel"],
                    "concrete": ["stone", "stein", "concrete"],
                    "glass": ["glass"],
                    "gold": ["gold"],
                    "granite": ["granite"],
                    "leather": ["leather", "textile", "stoff", "textil", "leder"],
                    "marble": ["marble"],
                    "plastic": ["plastic"],
                    "steel": ["steel", "metal", "metall", "eisen", "iron", "stahl"]
                }

                // get filename for prompt
                const filename = lookup(component.prompt, colorLookup)

                // if class was found
                if (filename) 
                {
                    // path to json
                    const path = `./colors/${filename}.json`

                    accessServerFile(path, function(text) {

                        // trigger callback
                        if (callbackSuccess && typeof callbackSuccess === 'function')
                            callbackSuccessWrapper({ response: JSON.parse(text) })

                    }, callbackError)     
                }
                // if class was not found
                else {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackErrorWrapper("no fitting color found")
                }
            }
        },











        //// IMAGE

        // TODO copy the following and rename layer

        // generate image from text
        async generateImageFromText({ rootState, commit, rootGetters }, {
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1, 
            steps = 6,
            cfg = 2,
            seeds = [ -1 ], 
            additional = null,
            callbackSuccess = null, 
            callbackError = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from text has no offline support')

                return
            }


            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (assets) => {

                // iterate through assets
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(assets)
            }

            // generate images
            accessRoute(
                ROUTE_IMAGES_FROMTEXT_GENERATE,
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    positive,
                    negative,
                    resolution,
                    denoising, 
                    steps,
                    cfg,
                    seeds,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },

        // generate image from image
        async generateImageFromImage({ rootState, commit, rootGetters }, {
            asset,
            guidance = {
                canny: {
                    preprocess: false,
                    strength: 0
                },
                depth: {
                    preprocess: false,
                    strength: 0
                }
            },
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            additional = null,
            callbackSuccess = null, 
            callbackError = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from image has no offline support')

                return
            }
            

            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (assets) => {

                // iterate through assets
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(assets)
            }

            // generate images
            accessRoute(
                ROUTE_IMAGES_FROMIMAGE_GENERATE,
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    asset_id: asset.id,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },








        async generateImageFromDepth({ rootState, commit, getters }, {
            image,
            guidance = 1.0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            callbackSuccess = null, 
            callbackError = null,
            callbackProgress = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from depth has no offline support')

                return
            }

            // generate images
            accessAPIFiles(
                ROUTE_IMAGES_FROMDEPTH_GENERATE,
                {
                    image,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    user: rootState.app.username
                },
                'image',
                (assets) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                },
                callbackProgress
            )
        },

        async segmentImage({ rootState, commit, getters }, { 
            image, 
            callbackSuccess = null, 
            callbackError = null 
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('segment image has no offline support')

                return
            }

            accessAPIFiles(
                ROUTE_IMAGES_SEGMENTATION_GENERATE,
                {
                    image: image, 
                    user: rootState.app.username
                },
                'image',
                (assets, additional) => {
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(assets, additional)
                },
                (error) => {
                    // trigger callback
                    if (callbackError && typeof callbackError === 'function')
                        callbackError(error)
                }
            )
        },



        //// LAYER

        // generate layer from text
        async generateLayerFromText({ rootState, commit, rootGetters }, {
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1, 
            steps = 4,
            cfg = 2,
            seeds = [ -1 ], 
            additional = null,
            callbackSuccess = null, 
            callbackError = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from text has no offline support')

                return
            }


            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (assets) => {

                // iterate through assets
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(assets)
            }

            // generate images
            accessRoute(
                ROUTE_LAYERS_FROMTEXT_GENERATE,
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    positive,
                    negative,
                    resolution,
                    denoising, 
                    steps,
                    cfg,
                    seeds,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },

        // generate layer from image
        async generateLayerFromImage({ rootState, commit, rootGetters }, {
            asset,
            guidance = {
                canny: {
                    preprocess: false,
                    strength: 0
                },
                depth: {
                    preprocess: false,
                    strength: 0
                }
            },
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            additional = null,
            callbackSuccess = null, 
            callbackError = null
        })
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate images from image has no offline support')

                return
            }
            

            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (assets) => {

                // iterate through assets
                assets.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(assets)
            }

            // generate images
            accessRoute(
                ROUTE_LAYERS_FROMIMAGE_GENERATE,
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    asset_id: asset.id,
                    guidance,
                    positive,
                    negative,
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },


        //// TEXTURE

        // import texture
        async importTexture({ state, commit, getters, dispatch }, 
            { 
                file, 
                callbackSuccess=null, 
                callbackError=null 
            })
        {
            // new material component for the imported texture
            const component = getters.newComponent('texture', 'import', null)

            // add material component to store
            commit('ADD_COMPONENT', component)

            // function for error handling
            const errorHandling = (error) => {

                // remove from list again
                dispatch('removeComponent', component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            } 

            // function for success handling
            const successHandling = (base64) =>  {
                // extract the image data
                const base64Data = base64.split(',')[1] || ''

                // ensure the image is not empty
                if (base64Data.length < 100) {
                    const error = new Error('imported texture is empty')
                    errorHandling(error)
                    return
                }

                // persist the requested data
                component.data.albedoMap = base64
                component.data.bumpMap = null
                component.data.normalMap = null

                // flag texture as reload necessary
                component.reload = true

                // flag texture as ready
                component.ready = true

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // load image texture
            accessClientImage(
                file, 
                successHandling,
                errorHandling
            )
        },

        // generate texture
        async generateTexture({ rootState, commit, getters, dispatch }, 
            { 
                prompt,
                type = 'albedo',
                tag = 'generic', 
                resolution = 512, 
                denoising = 1,
                steps = 6,
                cfg = 2,
                seeds = [ -1 ],
                callbackSuccess=null, 
                callbackError=null 
            }) 
        {
            // new material component for the generated texture
            const component = getters.newComponent('texture', tag, prompt)

            // add material component to array
            commit('ADD_COMPONENT', component)

            // function for error handling
            const callbackErrorWrapper = (error) => {

                // remove material component from list again
                dispatch('removeComponent', component.id)
                console.log(component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // function for success handling
            const callbackSuccessWrapper = (component) => {

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // api is active
            if (rootState.api.active) 
            {
                // API resource
                const API_RESOURCE = type == 'albedo' ? ROUTE_TEXTURES_ALBEDO_GENERATE : ROUTE_TEXTURES_DIFFUSE_GENERATE

                // create payload
                const payload = {
                    user: rootState.app.username,
                    positive: prompt.positive,
                    negative: prompt.negative ? prompt.negative : '',
                    resolution,
                    denoising,
                    steps,
                    cfg,
                    seeds
                }

                // custom texture material
                if (tag != 'generic')
                {
                    payload.image = prompt.svg
                    payload.guidance = 1
                }

                accessAPIFiles(
                    API_RESOURCE,
                    payload,
                    'texture',
                    function(assets) 
                    {
                        // persist the requested data
                        component.data.albedoMap = 'data:image/png;base64,' + assets[0]
                        component.data.bumpMap = null
                        component.data.normalMap = null

                        // flag texture as reload necessary
                        component.reload = true

                        // flag texture as ready
                        component.ready = true

                        // trigger callback
                        if (callbackSuccessWrapper && typeof callbackSuccessWrapper === 'function')
                            callbackSuccessWrapper(component)
                    },
                    callbackErrorWrapper
                )
            }

            // api is inactive
            else 
            {
                // texture lookup for offline processing
                const textureLookup = {
                    "wood": ["wood", "holz", "baum", "tree"],
                    "brick": ["brick", "ziegel"],
                    "sand": ["sand", "ground", "erde"],
                    "grass": ["grass", "green"],
                    "stone": ["stone", "stein", "kiesel", "gravel"],
                    "metal": ["metal", "metall", "eisen", "iron"],
                    "textile": ["textile", "stoff", "textil"]
                }

                // get filename for prompt
                const filename = lookup(prompt.positive, textureLookup)

                // if class was found
                if (filename) 
                {
                    // path to image
                    const rand = Math.floor((Math.random() * 4) + 1)
                    const path = `./textures/generic/${filename}/${rand}.png`

                    accessServerImage(path, function(base64) {

                        // persist the requested data
                        component.data.albedoMap = base64
                        component.data.bumpMap = null
                        component.data.normalMap = null

                        // flag texture as reload necessary
                        component.reload = true

                        // flag texture as ready
                        component.ready = true

                        // trigger callback
                        if (callbackSuccessWrapper && typeof callbackSuccessWrapper === 'function')
                            callbackSuccessWrapper(component)

                    }, callbackError)         
                }
                // if class was not found
                else {
                    // trigger callback
                    if (callbackErrorWrapper && typeof callbackErrorWrapper === 'function')
                        callbackErrorWrapper("no fitting texture found")
                }
            }
        },

        // generate texture
        async generateMaps({ rootState, commit, getters, dispatch }, 
            {
                component, 
                type = 'displacement',
                normal = true,
                callbackSuccess=null, 
                callbackError=null 
            }) 
        {
            // TODO implement offline support
            if (!rootState.api.active) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('segment image has no offline support')
    
                return
            }

            // API resource
            const API_RESOURCE = type == 'displacement' ? API_TEXTURE_DISPLACEMENT_GENERATE : API_TEXTURE_BUMP_GENERATE

            // payload 
            const payload = {
                image: component.data.albedoMap.substring(22),
                resolution: 512,
                user: 'testuser'
            }

            const finish = () => {
                // flag texture as reload necessary
                component.reload = true

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(component)
            }

            // TODO FIX ME -- new function
            accessAsset(
                API_RESOURCE, 
                payload, 
                'texture',
                function(assets) 
                {
                    // persist the requested data
                    component.data.bumpMap = 'data:image/png;base64,' + assets[0]

                    /*
                    if (normal)
                    {
                        // payload 
                        const payloadNormal = {
                            texture: assets[0]
                        }

                        accessAPIFiles(
                            API_TEXTURE_NORMAL_GENERATE,
                            payloadNormal, 
                            'texture',
                            function(normalAssets) 
                            {
                                // persist the requested data
                                component.data.normalMap = 'data:image/png;base64,' + normalAssets[0]

                                finish()
                            },
                            callbackError
                        )
                    }
                    else*/ 
                    finish()
                },
                callbackError
            )
        },








        //// MODEL

        async generateModelFromImage({ rootState, commit, getters, rootGetters }, {
            asset,
            resolution = 256,
            threshold = 20,
            additional = null,
            callbackSuccess = null, 
            callbackError = null 
        })
        {
            // TODO implement offline support
            if (!getters.isActive) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate model from image has no offline support')
                
                return
            }
                

            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (asset) => {

                // add asset to assets list
                commit('ADD_ASSET', asset)
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(asset)
            }

            // generate model
            accessRoute(
                ROUTE_MODELS_FROMIMAGE_GENERATE,
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    asset_id: asset.id,
                    resolution,
                    threshold,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },

        async generateModelFromLayer({ rootState, commit, getters, rootGetters }, {
            asset,
            resolution = 256,
            threshold = 20,
            additional = null,
            callbackSuccess = null, 
            callbackError = null 
        })
        {
            // TODO implement offline support
            if (!getters.isActive) 
            {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('generate model from image has no offline support')
                
                return
            }
                

            // get current user token
            const token = rootGetters['app/getToken']

            // get app name
            const app = rootGetters['app/getAppname']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (asset) => {

                // add asset to assets list
                commit('ADD_ASSET', asset)
                
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(asset)
            }

            // generate model
            accessRoute(
                ROUTE_MODELS_FROMLAYER_GENERATE, 
                {
                    user: rootState.app.username, // TODO remove when removed in API
                    asset_id: asset.id,
                    resolution,
                    threshold,
                    app,
                    additional
                },
                REST_POST,
                token,
                callbackSuccessWrapper,
                callbackError
            )
        },







        async generateModelFromScript({ rootState, commit, getters }, {})
        {

        },

        async convertModelAnythingToObj({ rootState, commit, getters }, {})
        {

        },

        async convertModelGLBToAnything({ rootState, commit, getters, rootGetters }, {
            asset,
            format,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // payload
            const payload = {
                asset_id: asset.id,
                format
            }

            // convert model
            accessRoute(
                ROUTE_MODELS_GLBTOANYTHING_CONVERT,
                payload,
                REST_POST,
                token,
                callbackSuccess,
                callbackError
            )
        },


        //// LOGGING
        
        // log event
        async logEvent({ getters, rootGetters }, { name, description }) 
        {
            // api not active or in development mode
            if (rootGetters['app/isDebugging'] || !getters.isActive)
            {
                console.warn("event not send to server")
                console.log("event_name: " + name + "\nevent_description: " + description)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootGetters['app/getUsername'],
                app_name: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion'],
                event_name: name,
                event_description: description
            }
            await accessRoute(ROUTE_LOGGING_EVENT, payload)
        },

        // log bug
        //TODO other log methods need to consider debugging and use getters insteadof rootState
        async logBug({ getters, rootGetters }, { name, description })
        {
            // api not active or in development mode
            if (rootGetters['app/isDebugging'] || !getters.isActive)
            {
                console.warn("bug not send to server")
                console.log("bug_name: " + name + "\nbug_description: " + description)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootGetters['app/getUsername'],
                app_name: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion'],
                event_name: name,
                event_description: description
            }
            await accessRoute(ROUTE_LOGGING_BUG, payload)
        },

        // log feedback
        async logFeedback({ getters, rootGetters }, { topic, feedback }) 
        {
            // api not active or in development mode
            if (rootGetters['app/isDebugging'] || !getters.isActive)
            {
                console.warn("feedback not send to server")
                console.log("feedback_topic: " + topic + "\nfeedback_value: " + feedback)
                return
            }

            // prepare server request
            const payload = {
                user_name: rootGetters['app/getUsername'],
                app_name: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion'],
                topic,
                feedback
            }
            await accessRoute(ROUTE_LOGGING_FEEDBACK, payload)
        },

        // fetch or get vote 
        async fetchVote({ commit, getters, rootGetters }, key) 
        {
            // if vote exists in state
            const vote = getters.getVote(key)
            if (vote) return

            // api not active or in development mode
            if (rootGetters['app/isDebugging'] || !getters.isActive)
            {
                // just assume the user has not voted yet
                commit('SET_VOTE', { key, value: false })
                return
            }

            // prepare server request
            const payload = {
                user_name: rootGetters['app/getUsername'],
                app_name: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion'],
                topic: key
            }

            // function to handle existing voting
            const callbackSuccess = (result) => {
                const voting = result.voting.toLowerCase()
                if (voting === 'true')
                    commit('SET_VOTE', { key, value: true })
                else 
                    commit('SET_VOTE', { key, value: false })
            }

            // function to handle not existing voting
            const callbackError = (error) => {
                // the user has not voted yet
                commit('SET_VOTE', { key, value: false })
            }

            // fetch vote flag from server
            await accessRoute(
                ROUTE_LOGGING_VOTE, 
                payload, 
                REST_GET, 
                null,
                callbackSuccess,
                callbackError
            )
        },

        // log voting
        async logVote({ commit, getters, rootGetters }, { key, flag }) 
        {
            // parameter flag is readonly
            let newFlag = flag

            // if flag parameter is undefined, toggle existing state
            if (newFlag === undefined) 
            {
                // get vote state
                newFlag = getters.getVote(key)
        
                // if vote not defined yet, we introduce it as voted for (true)
                if (newFlag === undefined)
                    newFlag = true
                else
                    newFlag = !newFlag
            }
 
            // api not active or in development mode
            if (rootGetters['app/isDebugging']|| !getters.isActive)
            {
                console.warn("vote not send to server")
                console.log("vote_key: " + key + "\nvote_flag: " + newFlag)
                return
            }
        
            // prepare server request
            const payload = {
                user_name: rootGetters['app/getUsername'],
                app_name: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion'],
                topic: key,
                voting: newFlag
            }
            await accessRoute(ROUTE_LOGGING_VOTE, payload, REST_PUT)
        
            // change vote in state
            commit('SET_VOTE', { key, value: newFlag })
        },


        //// MONITORING
        
        // status
        async fetchStatus({ commit, getters, rootGetters }, {
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            //const token = rootGetters['app/getToken']

            // callback wrapper function for success case
            const callbackSuccessWrapper = (response) => {

                // set status
                if (response)
                {
                    if (response.status == "up")
                    {
                        commit('SET_STATUS', MONITORING_STATUS_UP)
                    }
                    else
                    {
                        commit('SET_STATUS', MONITORING_STATUS_DOWN)
                    }
                }

                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(response)
            }

            // callback wrapper function for success case
            const callbackErrorWrapper = (error) => {

                // set status
                commit('SET_STATUS', MONITORING_STATUS_DOWN)
            
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            }

            // 
            await accessRoute(
                ROUTE_MONITORING_STATUS, 
                null, 
                REST_GET, 
                null, //token,
                callbackSuccessWrapper,
                callbackErrorWrapper
            )
        },


        //// ADMINISTRATION

        async fetchUsersActivity({ commit, getters, rootGetters }, {
            count = 5,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // 
            await accessRoute(
                ROUTE_ADMINISTRATION_USERS_ACTIVITY + '?count=' + count, 
                null, 
                REST_GET, 
                token,
                callbackSuccess,
                callbackError
            )
        },

        async fetchAssetsActivity({ commit, getters, rootGetters }, {
            count = 5,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // 
            await accessRoute(
                ROUTE_ADMINISTRATION_ASSETS_ACTIVITY + '?count=' + count, 
                null, 
                REST_GET, 
                token,
                callbackSuccess,
                callbackError
            )
        },

        async fetchNewsletterRecipients({ rootGetters }, {
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            // 
            await accessRoute(
                ROUTE_ADMINISTRATION_NEWSLETTERS,
                null, 
                REST_GET, 
                token,
                callbackSuccess,
                callbackError
            )
        },

        async sendNewsletter({ rootGetters }, {
            subject,
            body,
            callbackSuccess = null, 
            callbackError = null 
        } = {})
        {
            // get current user token
            const token = rootGetters['app/getToken']

            //
            const payload = {
                subject,
                body
            }

            // 
            await accessRoute(
                ROUTE_ADMINISTRATION_NEWSLETTERS_SEND,
                payload, 
                REST_POST, 
                token,
                callbackSuccess,
                callbackError
            )
        }
    }
}


//// API FUNCTIONS

// function to access API routes
// TODO hand over app vuex to automatically log out users when authentication errors happen
async function accessRoute(url, payload, method=REST_POST, token=null, callbackSuccess=null, callbackError=null) 
{
    try {
        // content of request
        const content = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                ...(token && { 'Authorization': 'Bearer ' + token }) // token only when specified
            }
        }

        // payload when post 
        if (method === REST_POST || method === REST_PUT)
            content.body = JSON.stringify(payload)
        // payload when get
        else if (method === REST_GET && payload && Object.keys(payload).length > 0) 
        {
            // serialize payload into query parameters
            const queryParams = new URLSearchParams(payload).toString()

            // append query parameters to URL
            url += `?${queryParams}`
        }

        // await response
        const response = await fetch(ROUTE_BASE + url, content)

        // throw error in case of trouble
        if (!response.ok)   
            throw { status: response.status, statusText: response.statusText }

        // returned json object
        const json = await response.json()

        // trigger callback
        if (callbackSuccess && typeof callbackSuccess === 'function')
            callbackSuccess(json)
    }
    catch (error) 
    {
        // get error code
        const statusCode = error.status || 'Unknown'

        // not a true error case (not unexpected)
        if (statusCode !== 404)
            console.error("unable to access the GenerIO API: ", statusCode)

        // trigger callback
        if (callbackError && typeof callbackError === 'function')
            callbackError(error)
    }
}