// vuex.js state for Boxurizer

// mode
const MODE_ITEM = 'item'
const MODE_CONTAINER = 'container'
const MODE_INFILL = 'infill'
const MODE_RENDER = 'render'
const MODE_EXPORT = 'export'
const MODE_DEFAULT = MODE_INFILL

export const boxurizer = {
    namespaced: true,
    state: {
        mode: MODE_DEFAULT
    },
    mutations: {
        SET_MODE(state, mode) {
            state.mode = mode
        }
    },
    getters: {
        keyModeDefault: () => MODE_DEFAULT,
        keyModeItem: () => MODE_ITEM,
        keyModeContainer: () => MODE_CONTAINER,
        keyModeInfill: () => MODE_INFILL,
        keyModeRender: () => MODE_RENDER,
        keyModeExport: () => MODE_EXPORT,
        getMode: (state) => state.mode,
        isModeDefault: (state) => state.mode === MODE_DEFAULT,
        isModeItem: (state) => state.mode === MODE_ITEM,
        isModeContainer: (state) => state.mode === MODE_CONTAINER,
        isModeInfill: (state) => state.mode === MODE_INFILL,
        isModeRender: (state) => state.mode === MODE_RENDER,
        isModeExport: (state) => state.mode === MODE_EXPORT
    },
    actions: {
        setMode({ commit }, mode) {
            commit('SET_MODE', mode)
        },
        setModeToDefault({ commit }) {
            commit('SET_MODE', MODE_DEFAULT)
        },
        setModeToItem({ commit }) {
            commit('SET_MODE', MODE_ITEM)
        },
        setModeToContainer({ commit }) {
            commit('SET_MODE', MODE_CONTAINER)
        },
        setModeToInfill({ commit }) {
            commit('SET_MODE', MODE_INFILL)
        },
        setModeToRender({ commit }) {
            commit('SET_MODE', MODE_RENDER)
        },
        setModeToExport({ commit }) {
            commit('SET_MODE', MODE_EXPORT)
        }
    }
}