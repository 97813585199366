// vuex.js state for Sketchurizer

// TODO: split to several files for different functions
// move processing completely into this library
import { processImage } from '@/utils' 


// window
const WINDOW_LIBRARY = 'library'
const WINDOW_INPUT = 'input'
const WINDOW_PREVIEW = 'preview'
const WINDOW_MODEL = 'model'
const WINDOW_DEFAULT = WINDOW_INPUT

// widgets
const WIDGET_SKETCH = 'sketch'
const WIDGET_UPLOAD = 'upload'
const WIDGET_CAMERA = 'camera'
const WIDGET_DEFAULT = WIDGET_SKETCH

// input
const INPUT_STRENGTH_DEFAULT = 0.5

// prompt
const PROMPT_POSITIVE_DEFAULT = 'plain background, long shot, single object' 
const PROMPT_NEGATIVE_DEFAULT = '' 

// sorting
const SORT_TYPE_DATE = 'date'
const SORT_TYPE_RATING = 'rating'
const SORT_ORDER_ASCENDING = 'ascending'
const SORT_ORDER_DESCENDING = 'descending'

export const sketchurizer = {
    namespaced: true,
    state: {
        activeInput: {
            raw: null,          // sketch output
            strokes: 0,  
            background: null,   // sketch input (e.g., upload output)
            upload: null,       // upload input
            asset: null,
            assetIgnore: false,
            assetOutdated: false
        },
        activePreviewAssets: null,
        activeModelAsset: null,

        activeWindow: WINDOW_DEFAULT,
        activeWidget: WIDGET_DEFAULT,

        inputStrength: INPUT_STRENGTH_DEFAULT,
        promptPositive: '',
        promptNegative: '',

        hints: {},
    },
    mutations: {
        RESET(state) {
            state.activeInput.raw = null
            state.activeInput.strokes = 0
            state.activeInput.background = null
            state.activeInput.upload = null
            state.activeInput.asset = null
            state.activeInput.assetIgnore = false
            state.activeInput.assetOutdated = false
            state.activePreviewAssets = null
            state.activeModelAsset = null

            state.inputStrength = INPUT_STRENGTH_DEFAULT
            state.promptPositive = ''
            state.promptNegative = ''
        },

        SET_ACTIVE_INPUT_RAW(state, raw) {
            state.activeInput.raw = raw

            if (state.activeInput.assetIgnore) {
                state.activeInput.assetIgnore = false
            } else {
                state.activeInput.assetOutdated = true
            }
        },
        SET_ACTIVE_INPUT_STROKES(state, strokes) {
            state.activeInput.strokes = strokes
        },
        SET_ACTIVE_INPUT_BACKGROUND(state, background) {
            state.activeInput.background = background
        },
        SET_ACTIVE_INPUT_UPLOAD(state, upload) {
            state.activeInput.upload = upload
        },
        SET_ACTIVE_INPUT_ASSET(state, asset) {
            const data = asset.files['default'].data
            state.activeInput.background = data
            state.activeInput.asset = asset
            state.activeInput.assetIgnore = true
            state.activeInput.assetOutdated = false
        },
        SET_ACTIVE_PREVIEW_ASSETS(state, assets) {
            state.activePreviewAssets = assets
        },
        SET_ACTIVE_MODEL_ASSET(state, asset) {
            state.activeModelAsset = asset
        },

        SET_ACTIVE_WINDOW(state, window) {
            state.activeWindow = window
        },
        SET_ACTIVE_WIDGET(state, widget) {
            state.activeWidget = widget
        },

        SET_INPUT_STRENGTH(state, strength) {
            state.inputStrength = strength
        },
        SET_PROMPT_POSITIVE(state, prompt) {
            state.promptPositive = prompt
        },
        SET_PROMPT_NEGATIVE(state, prompt) {
            state.promptNegative = prompt
        },

        SET_HINT(state, { hint, value }) {
            state.hints = {
              ...state.hints,
              [hint]: value
            }
        },
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowLibrary: () => WINDOW_LIBRARY,
        keyWindowInput: () => WINDOW_INPUT,
        keyWindowPreview: () => WINDOW_PREVIEW,
        keyWindowModel: () => WINDOW_MODEL,

        keyWidgetSketch: () => WIDGET_SKETCH,
        keyWidgetUpload: () => WIDGET_UPLOAD,
        keyWidgetCamera: () => WIDGET_CAMERA,

        keyInputStrengthDefault: () => INPUT_STRENGTH_DEFAULT,

        keyPromptPositiveDefault: () => PROMPT_POSITIVE_DEFAULT,
        keyPromptNegativeDefault: () => PROMPT_NEGATIVE_DEFAULT,

        keySortTypeDate: () => SORT_TYPE_DATE,
        keySortTypeRating: () => SORT_TYPE_RATING,

        keySortOrderAscending: () => SORT_ORDER_ASCENDING,
        keySortOrderDescending: () => SORT_ORDER_DESCENDING,

        getActiveInputRaw: (state) => state.activeInput.raw,
        getActiveInputStrokes: (state) => state.activeInput.strokes,
        getActiveInputBackground: (state) => state.activeInput.background,
        getActiveInputUpload: (state) => state.activeInput.upload,
        getActiveInputAsset: (state) => state.activeInput.asset,
        getActivePreviewAssets: (state) => state.activePreviewAssets,
        getActiveModelAsset: (state) => state.activeModelAsset,

        getAllInputAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyTypeImage']
            )
        },
        getAllPreviewAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets']( 
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyTypeLayer']
            )
        },
        getAllModelAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets']( 
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyTypeModel']
            )
        },

        getAssetCategory: (state, getters, rootState, rootGetters) => (asset) => {
            if (asset.type === rootGetters['api/keyTypeImage']) return 'input'
            if (asset.type === rootGetters['api/keyTypeLayer']) return 'preview'
            return asset.type
        },

        getActiveWindow: state => state.activeWindow,
        getActiveWidget: state => state.activeWidget,

        getInputStrength: state => state.inputStrength,
        getPromptPositive: state => state.promptPositive,
        getPromptNegative: state => state.promptNegative,

        getHint: (state) => (hint) => {
            return true //TODO temporaly disabled: state.hints[hint] || false
        },

        hasActiveInputAsset: state => state.activeInput.asset !== null,
        hasActivePreviewAssets: state => state.activePreviewAssets !== null,
        hasActiveModelAsset: state => state.activeModelAsset !== null,

        isActiveInputEmpty: state => {
            if (state.activeInput.raw === null) return true
            if (state.activeInput.background !== null) return false
            if (state.activeInput.strokes !== null && state.activeInput.strokes > 0) return false
            return true
        },
        isActiveInputAssetOutdated: state => state.activeInput.assetOutdated,

        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowLibrary: state => state.activeWindow === WINDOW_LIBRARY,
        isWindowInput: state => state.activeWindow === WINDOW_INPUT,
        isWindowPreview: state => state.activeWindow === WINDOW_PREVIEW,
        isWindowModel: state => state.activeWindow === WINDOW_MODEL,

        isWidgetDefault: state => state.activeWidget === WIDGET_DEFAULT,
        isWidgetSketch: state => state.activeWidget === WIDGET_SKETCH,
        isWidgetUpload: state => state.activeWidget === WIDGET_UPLOAD,
        isWidgetCamera: state => state.activeWidget === WIDGET_CAMERA
    },
    actions: {

        reset({ commit }) {
            commit('RESET')
        },

        // open an asset (sketch, upload, preview, or model)
        async openAsset({ dispatch, rootGetters }, {
            asset,
            callbackSuccess = null,
            callbackError = null
        } = {}) 
        {
            // ensure asset is not null
            if (asset == null) return

            // reset store
            dispatch('reset')

            // helper function to ensure an asset file is retrieved
            const getAssetFile = (asset, callback) => {
                dispatch('api/getAssetFile', {
                    asset,
                    callbackSuccess: callback,
                    callbackError,
                }, { root: true })
            }

            // helper function to open sketch asset
            const openInputAsset = (inputAsset, callback) => {
                getAssetFile(inputAsset, (rawData) => {
                    dispatch('setActiveWindowToInput')
                    dispatch('setActiveWidgetToSketch')
                    //dispatch('setActiveInputBackground', rawData)
                    dispatch('setActiveInputAsset', inputAsset)
                    dispatch('setPromptPositive', inputAsset.additional.positive)
                    dispatch('setPromptNegative', inputAsset.additional.negative)

                    // trigger callback
                    if (callback && typeof callback === 'function')
                        callback()
                })
            }

            // helper function to open preview asset
            const openPreviewAsset = (previewAsset, callback) => {
                dispatch('setActivePreviewAssets', [ previewAsset ])
                dispatch('setPromptPositive', previewAsset.additional.positive)
                dispatch('setPromptNegative', previewAsset.additional.negative)

                const strength = previewAsset.additional?.guidance
                if (strength !== null && strength !== undefined) dispatch('setInputStrength', strength)

                if (previewAsset.parent) 
                {
                    const inputAsset = rootGetters['api/getAsset'](previewAsset.parent)

                    if (inputAsset) openInputAsset(inputAsset, callback)
                    else {
                        // trigger callback
                        if (callback && typeof callback === 'function')
                            callback()
                    }
                }
                else
                {
                    // trigger callback
                    if (callback && typeof callback === 'function')
                        callback()
                }
            }

            // helper function to open model asset
            const openModelAsset = (modelAsset, callback) => {
                dispatch('setActiveModelAsset', modelAsset)

                if (modelAsset.parent) 
                {
                    const previewAsset = rootGetters['api/getAsset'](modelAsset.parent)
                    if (previewAsset) openPreviewAsset(previewAsset, callback)
                    else {
                        // trigger callback
                        if (callback && typeof callback === 'function')
                            callback()
                    }
                }
                else 
                {
                    // trigger callback
                    if (callback && typeof callback === 'function')
                        callback()
                }
            }

            // input asset
            if (asset.type === rootGetters['api/keyTypeImage']) 
            {
                openInputAsset(asset, () => {
                    dispatch('setActiveWindowToInput')
                    dispatch('setActiveWidgetToSketch')

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(asset)
                })
            }
                
            // preview asset
            else if (asset.type === rootGetters['api/keyTypeLayer'])
            {
                openPreviewAsset(asset, () => {
                    dispatch('setActiveWindowToPreview')  

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(asset)
                })
            }
    
            // model asset
            else if (asset.type === rootGetters['api/keyTypeModel'])
            {
                openModelAsset(asset, () => {
                    dispatch('setActiveWindowToModel')   

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(asset)
                })
            }

            else {
                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError('type not supported')
            }
        },

        editAsset({ dispatch, rootGetters }, {
            asset,
            callbackSuccess = null,
            callbackError = null
        } = {})
        {
            // if type is not image / layer abort (TODO callbackError)
            if (asset == null) return 
            if (asset.type != rootGetters['api/keyTypeLayer'] &&
                asset.type != rootGetters['api/keyTypeImage']) return

            // download the asset file if not available
            dispatch('api/getAssetFile', {
                asset,
                callbackSuccess: (rawData) => {

                    // set raw as input background
                    dispatch('setActiveInputBackground', rawData)

                    // reset any existing upload
                    dispatch('setActiveInputUpload', null)

                    // set active window to input
                    dispatch('setActiveWindowToInput')

                    // set active widget to sketch
                    dispatch('setActiveWidgetToSketch')

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(asset)
                },
                callbackError,
            }, { root: true })
        },

        // download an asset (sketch, upload, preview, or model)
        downloadAsset({ dispatch, rootGetters }, {
            asset,
            format = null,
            callbackSuccess = null,
            callbackError = null
        } = {})
        {
            // helper function to convert ascii to binary
            const toBinary = (data) => {
                const base64 = data.split(',')[1] 
                const binaryString = atob(base64)
                const len = binaryString.length
                const arrayBuffer = new ArrayBuffer(len)
                const uint8Array = new Uint8Array(arrayBuffer)
                for (let i = 0; i < len; i++) {
                    uint8Array[i] = binaryString.charCodeAt(i)
                }
                return uint8Array
            }

            // helper function for download
            const download = (data, type, extension) => {
                const blob = new Blob([data], { type })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = `generio_${asset.type}_${asset.id}.${extension}`
                link.click()
            }

            // callback wrapper function for success case
            const callbackSuccessWrapper = () => {

                // get asset file date
                const asciiData = asset.files['default'].data
                
                // create download based on asset type
                if (asset.type === rootGetters['api/keyTypeModel'])
                {
                    if (format == null || format == 'glb')
                    {
                        const binaryData = toBinary(asciiData)
                        download(binaryData, 'model/gltf-binary', 'glb')

                        // trigger callback
                        if (callbackSuccess && typeof callbackSuccess === 'function')
                            callbackSuccess(asset)
                    }
                    else
                    {
                        dispatch('api/convertModelGLBToAnything', {
                            asset,
                            format,
                            callbackSuccess: (result) => {

                                const binaryData = toBinary(result)
                                download(binaryData, 'model/' + format, format)
       

                                // trigger callback
                                if (callbackSuccess && typeof callbackSuccess === 'function')
                                    callbackSuccess(asset)
                            },
                            callbackError
                        }, { root: true })
                    }
                }
                else
                {
                    const binaryData = toBinary(asciiData)
                    download(binaryData, 'image/png', 'png')
                }
            }
            
            // ensure asset file is available locally
            dispatch('api/getAssetFile', {
                asset,
                callbackSuccess: callbackSuccessWrapper,
                callbackError,
            }, { root: true })
        },

        // delete an asset (sketch, upload, preview, or model)
        deleteAsset({ commit, dispatch, getters, rootGetters }, {
            asset,
            callbackSuccess = null,
            callbackError = null
        } = {})
        {
            // get previews
            let previews = getters.getActivePreviewAssets

            // delete if preview asset and in previewAssets
            if (previews) 
            {
                previews = previews.filter(preview => preview.id !== asset.id)
                commit('SET_ACTIVE_PREVIEW_ASSETS', previews)
            }

            // delete in API
            dispatch('api/deleteAsset', {
                asset,
                callbackSuccess,
                callbackError
            }, { root: true })
        },

        async setInputAssetFromRaw({ commit, dispatch, getters, rootGetters }, {
            additional = null,
            callbackSuccess = null,
            callbackError = null    
        } = {})
        {
            // check if a new asset should be created
            if (getters.isActiveInputAssetOutdated || getters.getActiveInputAsset === null)
            {
                // get raw input
                const inputRaw = getters.getActiveInputRaw

                // specify attributes
                const type = rootGetters['api/keyTypeImage']
                const subtype = rootGetters['api/keySubtypeImagePhoto']

                // source depends on if a sketch is present
                let source = rootGetters['api/keySourceUpload']
                if (getters.isActiveInputCreation)
                    source = rootGetters['api/keySourceCreation']
    
                // create thumbnail
                const resolution = rootGetters['app/keyDefaultThumbnailResolution']
                const thumbnail = await processImage(
                    inputRaw, 
                    resolution,
                    resolution
                )
    
                // successfully created input asset
                const callbackSuccessWrapper = (asset) => {
    
                    // set input asset
                    commit('SET_ACTIVE_INPUT_ASSET', asset)
    
                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(asset)
                }
    
                // create asset
                dispatch('api/createAsset', {
                    type,
                    subtype,
                    source,
                    files: inputRaw,
                    thumbnail,
                    additional,
                    callbackSuccess: callbackSuccessWrapper,
                    callbackError
                }, { root: true })
            }
            else
            {
                // trigger callback
                if (callbackSuccess && typeof callbackSuccess === 'function')
                    callbackSuccess(getters.getActiveInputAsset)
            }
        },

        setActiveInputRaw({ commit }, raw) {
            commit('SET_ACTIVE_INPUT_RAW', raw)
        },
        setActiveInputStrokes({ commit }, strokes) {
            commit('SET_ACTIVE_INPUT_STROKES', strokes)
        },
        setActiveInputBackground({ commit }, background) {
            commit('SET_ACTIVE_INPUT_BACKGROUND', background)
        },
        setActiveInputUpload({ commit }, upload) {
            commit('SET_ACTIVE_INPUT_UPLOAD', upload)
        },
        setActiveInputAsset({ commit }, asset) {
            commit('SET_ACTIVE_INPUT_ASSET', asset)
        },
        setActivePreviewAssets({ commit }, assets) {
            commit('SET_ACTIVE_PREVIEW_ASSETS', assets)
        },
        setActiveModelAsset({ commit }, asset) {
            commit('SET_ACTIVE_MODEL_ASSET', asset)
        },

        setActiveWindow({ commit }, window) {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToLibrary({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_LIBRARY)
        },
        setActiveWindowToInput({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_INPUT)
        },
        setActiveWindowToPreview({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_PREVIEW)
        },
        setActiveWindowToModel({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_MODEL)
        },

        setActiveWidget({ commit }, widget) {
            commit('SET_ACTIVE_WIDGET', widget)
        },
        setActiveWidgetToSketch({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_SKETCH)
        },
        setActiveWidgetToUpload({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_UPLOAD)
        },
        setActiveWidgetToCamera({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_CAMERA)
        },

        setInputStrength({ commit }, strength) {
            commit('SET_INPUT_STRENGTH', strength)
        },
        setPromptPositive({ commit }, prompt) {
            commit('SET_PROMPT_POSITIVE', prompt)
        },
        setPromptNegative({ commit }, prompt) {
            commit('SET_PROMPT_NEGATIVE', prompt)
        },

        setHint({ commit }, payload) {
            commit('SET_HINT', payload)
        }
    }
}